import "./hero.css";
import logo from "../../archive/IMG_4652.png";

export default function Hero() {
  return (
    <div className="hero">
      <div className="splashLogo">
        <img src={logo} alt="logo" />
      </div>
      {/* <p className="hero-subtitle">The best food in town</p> */}
    </div>
  );
}
