import "./contact.css";
import { db } from "../../firebase/firebase";
import { collection, getDoc, doc } from "firebase/firestore";
import { useEffect, useState } from "react";

export default function Contact() {
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    const getDocData = async () => {
      const docRef = doc(db, "bio", "contact");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setPhone(docSnap.data().phone);
        setEmail(docSnap.data().email);
      } else {
        console.log("No such document!");
      }
    };

    const contactPhone = document
      .querySelector(".contactInfo")
      .getElementsByClassName("phone")[0].textContent;

    console.log(contactPhone);

    const contactEmail = document
      .querySelector(".contactInfo")
      .getElementsByClassName("email")[0].textContent;

    console.log(contactEmail);

    getDocData();
  });

  return (
    <section id="contact">
      <h2 className="sectionTitle">Contact</h2>
      <div className="contactContainer">
        <div className="contactInfo">
          <h2>Veteran-Owned Business 🇺🇸</h2>
          <h3>Phone</h3>
          <p className="phone" data-editable>
            {phone}
          </p>
          <a href={`tel:+${phone}`} className="contactButton">
            Call
          </a>
          <a href={`sms:+${phone}`} className="contactButton">
            Text
          </a>
          <h3>Email</h3>
          <p className="email" data-editable>
            {email}
          </p>
          <a href={`mailto:${email}`} className="contactButton">
            Email
          </a>
          <div className="socials">
            <a
              href="https://www.facebook.com/share/xfyrHsNVA4brh6U6/?mibextid=LQQJ4d"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                width={50}
                fill="#4267B2"
              >
                <path d="M512 256C512 114.6 397.4 0 256 0S0 114.6 0 256C0 376 82.7 476.8 194.2 504.5V334.2H141.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H287V510.1C413.8 494.8 512 386.9 512 256h0z" />
              </svg>
            </a>
            <a
              href="https://www.instagram.com/kenscasualkatering?igsh=aDZjeWgzNmsxZmFr&utm_source=qr"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                width={50}
              >
                <defs>
                  <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop
                      offset="0%"
                      style={{ stopColor: "#405DE6", stopOpacity: 1 }}
                    />
                    <stop
                      offset="25%"
                      style={{ stopColor: "#833AB4", stopOpacity: 1 }}
                    />
                    <stop
                      offset="50%"
                      style={{ stopColor: "#C13584", stopOpacity: 1 }}
                    />
                    <stop
                      offset="75%"
                      style={{ stopColor: "#F77737", stopOpacity: 1 }}
                    />
                    <stop
                      offset="100%"
                      style={{ stopColor: "#FFDC80", stopOpacity: 1 }}
                    />
                  </linearGradient>
                </defs>
                <path
                  fill="url(#grad1)"
                  d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
