import React, { useEffect, useState } from "react";
import "./menu.css";
import { db } from "../../firebase/firebase";
import { collection, getDocs } from "firebase/firestore";

export default function Menu() {
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    const getMenuItems = async () => {
      const querySnapshot = await getDocs(collection(db, "menu"));
      querySnapshot.forEach((doc) => {
        setMenuItems((prev) => [...prev, { key: doc.id, data: doc.data() }]);
      });
      console.log(menuItems);
    };

    getMenuItems();
  }, []);
  return (
    <section className="menu" id="menu">
      <h2 className="sectionTitle">Our Menu</h2>
      <div className="menuContainer">
        <hr />

        {menuItems.map((item) => (
          <div className="menuItem" key={item.key} data-key={item.key}>
            <div className="menu-item-contents">
              <h2 className="menu-item-title" data-editable>
                {item.data.title}
              </h2>
              {item.data.sections.map((section, index) => (
                <p key={index} className="menu-item-description" data-editable>
                  {section}
                </p>
              ))}
            </div>
            <span className="menu-item-price" data-editable>
              {item.data.price}
            </span>
          </div>
        ))}
      </div>
      <hr />
      <div className="otherListings">
        <h2>All KCK Menu Options include decorated buffet table</h2>
        <h3>Beverage Station</h3>
        <p>Water, Iced Tea, Lemonade, Non-Alcohlic Punch</p>
        <h3>Additional Fees</h3>
        <p>All condiments, all clear plastic eating and drinking utensils</p>
        <p>Disposable/Stainless Steel chaffing dishes w/ serving utensils</p>
        <p>Staffing to set-up and serve 3.5 hours</p>
        <p>Other Menu Options Available At Additional Cost</p>
        <h3>Free Tastings</h3>
        <p>No changes or substitutions</p>
      </div>
    </section>
  );
}
