import { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import "./about.css";

export default function About() {
  const [about, setAbout] = useState([]);

  useEffect(() => {
    const getAbout = async () => {
      const docRef = doc(db, "bio", "i6KgmOBXkivpBPjI39Ea");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setAbout(docSnap.data());
      }
    };

    getAbout();
  }, []);

  return (
    <div className="about" id="about">
      <h4 className="poppins-medium bio" data-editable>
        {about.main}
      </h4>
      <span className="poppins-light-italic joke" data-editable>
        {about.quote}
      </span>
    </div>
  );
}
