import React from "react";
import "./navbar.css";

export default function Navbar() {
  return (
    <div className="navbar">
      <ul className="navbar-ul poppins-bold">
        <li>
          <a href="#about">About</a>
        </li>
        <li>
          <a href="#menu">Menu</a>
        </li>
        <li>
          <a href="#contact">Contact</a>
        </li>
      </ul>
      <div className="bookingButton ">
        <button className="poppins-bold">
          <a href="#contact">Book Us!</a>
        </button>
      </div>
    </div>
  );
}
