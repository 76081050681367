import "./carousel2.css";
import image1 from "../../archive/IMG_5004.jpg";
import image2 from "../../archive/IMG_5006.jpg";
import image3 from "../../archive/IMG_5008.jpg";
import image4 from "../../archive/IMG_5011.jpg";

export default function Carousel() {
  const images = [image1, image2, image3, image4];

  return (
    <div className="Carousel">
      <div className="slide-track">
        {images.map((meal, i) => {
          return (
            <div className="card" key={meal.id}>
              <img src={images[i]} alt="" />
            </div>
          );
        })}
        {images.map((meal, i) => {
          return (
            <div className="card" key={meal.id}>
              <img src={images[i]} alt="" />
            </div>
          );
        })}
        {images.map((meal, i) => {
          return (
            <div className="card" key={meal.id}>
              <img src={images[i]} alt="" />
            </div>
          );
        })}
        {images.map((meal, i) => {
          return (
            <div className="card" key={meal.id}>
              <img src={images[i]} alt="" />
            </div>
          );
        })}
        {images.map((meal, i) => {
          return (
            <div className="card" key={meal.id}>
              <img src={images[i]} alt="" />
            </div>
          );
        })}
      </div>
    </div>
  );
}
