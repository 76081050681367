import Navbar from "./components/navbar/navbar";
import Hero from "./components/hero/hero";
import About from "./components/about/about";
import Carousel from "./components/carousel/carousel.jsx";
import Carousel2 from "./components/carousel/carousel2";
import Menu from "./components/menu/menu";
import Contact from "./components/contact/contact";
import Footer from "./components/footer/footer.jsx";

function App() {
  return (
    <>
      <Navbar />
      <Hero />
      <About />
      <Carousel />
      <Menu />
      <hr />
      <Carousel2 />
      <Contact />
      <Footer />
    </>
  );
}

export default App;
