import "./carousel.css";
import image1 from "../../archive/12.jpg";
import image2 from "../../archive/13.jpg";
import image3 from "../../archive/IMG_2738.jpg";
import image4 from "../../archive/IMG_4525.jpg";
import image5 from "../../archive/IMG_4530.jpg";
import image6 from "../../archive/IMG_2783.jpg";

export default function Carousel() {
  const images = [image1, image2, image3, image4, image5, image6];

  return (
    <div className="Carousel">
      <div className="slide-track">
        {images.map((meal, i) => {
          return (
            <div className="card" key={meal.id}>
              <img src={images[i]} alt="" />
            </div>
          );
        })}
        {images.map((meal, i) => {
          return (
            <div className="card" key={meal.id}>
              <img src={images[i]} alt="" />
            </div>
          );
        })}
        {images.map((meal, i) => {
          return (
            <div className="card" key={meal.id}>
              <img src={images[i]} alt="" />
            </div>
          );
        })}
        {images.map((meal, i) => {
          return (
            <div className="card" key={meal.id}>
              <img src={images[i]} alt="" />
            </div>
          );
        })}
        {images.map((meal, i) => {
          return (
            <div className="card" key={meal.id}>
              <img src={images[i]} alt="" />
            </div>
          );
        })}
      </div>
    </div>
  );
}
